<template>
  <v-col class="regads mt-2">
    <v-col class="shades white" :class="$vuetify.breakpoint.mdAndUp ? 'pa-10' : 'pa-6'">
      <v-row class="mb-5">
        <v-col cols="12" md="12">
          <div class="header">
            선호하는 <span class="font-weight-bold text-decoration-underline">체험단 운영 방식</span>을 선택해 주세요.
          </div>
        </v-col>
      </v-row>
      <v-row style="gap: 8px">
        <v-col @click="selectOperationType(AD.STORE_OPERATION_TYPE.MASS_REVIEW)">
          <div class="radio-box" :class="{ selected: form.operationType === AD.STORE_OPERATION_TYPE.MASS_REVIEW }">
            <div class="radio-box-header">
              <v-icon size="20">mdi-radiobox-marked</v-icon>
              <div class="label">리뷰를 최대한 빨리 & 많이 쌓고 싶어요. <br />(신규 매장 추천)</div>
            </div>
          </div>
        </v-col>
        <v-col @click="selectOperationType(AD.STORE_OPERATION_TYPE.BLOG_EXPOSURE)">
          <div class="radio-box" :class="{ selected: form.operationType === AD.STORE_OPERATION_TYPE.BLOG_EXPOSURE }">
            <div class="radio-box-header">
              <v-icon size="20">mdi-radiobox-marked</v-icon>
              <div class="label">인원이 적더라도, 상위노출을 잘하는 <br />블로거 위주로 받고 싶어요.</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
import * as AD from '@/constants/ad';

export default {
  name: 'Page2',
  components: {},
  props: ['adsData', 'option'],
  data: function () {
    return {
      AD,
      form: {
        operationType: this.adsData.operationType || AD.STORE_OPERATION_TYPE.MASS_REVIEW,
      },
    };
  },
  methods: {
    selectOperationType(operationType) {
      this.form.operationType = operationType;
      this.checkDone();
    },
    checkDone() {
      this.form.step = 2;
      this.$emit('updateEvent', this.form);
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.radio-box {
  display: flex;
  width: 100%;
  min-width: 292px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--disabled-color);
  cursor: pointer;
}

.radio-box-header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.selected {
  background-color: rgba(234, 42, 58, 0.05);

  i {
    color: var(--primary-light-color);
  }
  div {
    color: #000;
  }
}
</style>
